@use "@causevest/ui-kit/styles";

.wrapper {
  min-width: 700px;
  gap: 30px;
}

.row {
  flex-direction: row;
  align-items: flex-start;
  gap: 25px;

  > * {
    width: 50%;
  }

  &._lonely {
    > * {
      width: 100%;
    }
  }
}

.switchers {
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.updateBtn {
  width: 125px;
  height: 35px;
}

@media (max-width: styles.$sm) {
  .wrapper {
    min-width: 100%;
    gap: 20px;
  }

  .row {
    flex-direction: column;
    gap: 20px;

    > * {
      width: 100%;
    }
  }
}
